import React, {useState, useEffect} from 'react'
import {db} from '../firebase'
import {withRouter} from 'react-router-dom'

const Bd = (props) => {

    const [registros, setRegistros] = useState([])
    useEffect(()=>{
        const cargaBase = async()=> {
            try{
                const res = await db.collection('registros').get()
                const arrayData = res.docs.map(item => (
                    {id:item.id, ...item.data()}
                ))
                setRegistros(arrayData)  
            }
            catch(error){
                console.log(error)
            }          
        }
        cargaBase()
    },[setRegistros])

    useEffect(()=>{
        const validaUser = async()=>{
            try{
                await db.collection('acceso').doc('t5jdtceMQ645SKGuDmsB').get()
    
                .then((doc) => {
                    if (doc.exists) {
                        // Validación de usuario y pass //
                        if(props.usuario !== doc.data().usuario){
                            props.history.push('/')
                            return
                        }
                        if(props.password !== doc.data().password){
                            props.history.push('/')
                            return
                        }
                    } else {
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            }
            catch(error){
                console.log(error)
            }
        }
        validaUser()
    },[props.history, props.usuario, props.password])

    return (
        <table class="table table-hover table-bordered bg-white border-bottom border-4">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Email</th>
                    <th scope="col">RUT</th>
                    <th scope="col">Teléfono</th>
                </tr>
            </thead>
            <tbody>
                {
                    registros.map((registro, index) => (
                        <tr key={registro.id}>
                            <td>{index}</td>
                            <td>{registro.nombre}</td>
                            <td>{registro.email}</td>
                            <td>{registro.rut}</td>
                            <td>{registro.telefono}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

export default withRouter(Bd)
