import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {db} from '../firebase'

const Login = (props) => {

    const [error, setError] = useState(null)

    const submitForm = (event) => {
        event.preventDefault()
        if(!props.usuario.trim()){
            setError('Ingrese un usuario')
            return
        }
        if(!props.password.trim()){
            setError('Ingrese un password')
            return
        }
        Log()
        
    }

    const Log = async()=>{
        try{
            await db.collection('acceso').doc('t5jdtceMQ645SKGuDmsB').get()

            .then((doc) => {
                if (doc.exists) {
                    // Validación de usuario y pass //
                    if(props.usuario !== doc.data().usuario){
                        setError('El usuario no coincide')
                        return
                    }
                    if(props.password !== doc.data().password){
                        setError('El password no coincide')
                        return
                    }
                    props.history.push('/bd')
                } else {
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }
        catch(error){
            console.log(error)
        }
    }

    return (
        <div className="row justify-content-center">
            <div className="col-5">
                { error ? 
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                    : 
                    null
                }
                <form onSubmit={submitForm}>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Usuario</label>
                        <input 
                            name="usuario"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            onChange={e=>props.setUsuario(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                        <input 
                            name="password"
                            type="password"
                            className="form-control"
                            id="exampleInputPassword1"
                            onChange={e=>props.setPassword(e.target.value)}
                        />
                    </div>
                    <button 
                        type="submit"
                        className="d-block w-100 btn btn-primary"
                    >Ingresar</button>
                </form>
            </div>
        </div>
    )
}

export default withRouter(Login)
