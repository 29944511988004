import React, {useState} from 'react'
import Login from './components/Login'
import Bd from './components/Bd'
import {BrowserRouter as Router, Switch,Route} from "react-router-dom";

function App() {

  const [usuario, setUsuario] = useState('')
  const [password, setPassword] = useState('')

  return (
    <Router>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-8">
          <Switch>
            <Route path="/" exact><Login usuario={usuario} setUsuario={setUsuario} password={password} setPassword={setPassword}/></Route>
            <Route path="/bd" exact><Bd usuario={usuario} password={password}/></Route>
          </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
